










import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CheckoutFlow extends Vue {
  step: number = 0;

  get checkoutStep() {
    return this.$store.state.checkout_step;
  }

  // goToStep(step: number) {
  //   this.step = step;
  //   location.href = "#" + this.step;

  //   if(step === 1) {
  //     this.$router.push({name: 'plans'});
  //   }
  // }

  mounted() {
    this.$gtag.event('pmb_app_checkout_plan_selector_mounted');
  }

  goToPlans() {
    this.$router.push({name: 'plans'});
  }

}
